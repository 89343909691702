.configuratorPage {
    min-height: 100vh;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.containerDrop {
    display: flex;
    touch-action: none;
    width: 1200px;
    margin: 1rem auto;
}

.dropzone {
    flex: 1 1;
    height: 600px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
}

.contactedSofaButton {
    background-color: rgb(28, 184, 28) !important;
    cursor: default !important;
}

.miniLabel {
    border-radius: 100px;
    overflow: hidden;
    margin-left: 10px;
}

.chooseDifferentStore {
    background-color: black;
    border: none;
    color: white;
    padding: 10px 15px;
    border-radius: 3px;
    margin-top: 20px;
}

.redDiscount {
    color: rgba(255, 0, 0, 0.973) !important;
}

.uChooseContainer {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.configHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: white;
    font-weight: bold;
    bottom: 8px;
}

.sofaConfigHeader {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 10;
    width: 100%;
    padding: 30px 0;
    display: flex;
    text-align: center;
    background-color: black;
}

.configHeader_sec {
    width: 33%;
}

.configHeader_sec.first {
    text-align: left;
}

.configHeader_sec.first a {
    color: white;
}

.configHeader_sec.first .back {
    margin-right: 10px;
}

.configHeader_sec.middle {
    font-weight: bold;
    font-size: 20px;
}

.configHeader_sec.last {
    text-align: right;
}

.sofaInfo {
    font-size: 1.5em;
    cursor: pointer;
}

.configTitleHolder {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.configHolder {
    max-width: 2000px;
    padding-top: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.068);
}

.configFirst {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.configThird {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}

.configFirst .configuratorDisplay {
    margin: 20px 0;
    justify-content: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.configSecond .colorHolder {
    display: flex;
    justify-content: center;
    padding: 5px;
    flex-wrap: wrap;
}

.configSecond .configuratorDisplay {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.configTitle {
    margin: 10px;
    font-weight: bold;
}

.configTitle.dimensions {
    margin: 13px 10px;
    text-align: end;
    font-size: 0.8em;
    font-weight: bold;
}

.sofaValidation {
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
    padding: 10px;
}

.colorDetailsButton {
    margin-top: 20px;
    border: none;
    border-radius: 4px;
    background: rgb(59, 59, 59);
    color: white;
    padding: 5px 10px;
    /* font-weight: bold; */
    font-size: 0.8em;
    transition: 0.3s;
}

.colorDetailsButton:hover {
    background: white;
    color: rgb(83, 83, 83);
}

.centerc {
    text-align: end;
    display: flex;
    align-items: center;
}

.rightc {
    text-align: end;
    display: flex;
}

.configFirst .leftMenu,
.configSecond .leftMenu {
    display: flex;
    width: 30%;
    flex-wrap: wrap;
    flex-direction: column;
    background: rgb(248, 248, 248);
}

.configThird .rightMenu {
    display: flex;
    width: 30%;
    flex-direction: column;
    background: rgb(248, 248, 248);
}

.rightMenu .holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    background: rgb(248, 248, 248);
}

.configFirst .summaryDisplay {
    display: flex;
    width: 70%;
    flex-direction: row;
    border-top: 6px solid rgb(248, 248, 248);
    border-bottom: 6px solid rgb(248, 248, 248);
}

.configSecond .summaryDisplay {
    display: flex;
    border-bottom: 6px solid rgb(248, 248, 248);
    width: 70%;
    flex-direction: column;
}

.configSecond .selectedColorHolder {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    border-radius: 5%;
    margin: 5px 10px;
    padding: 5px;
    width: 100%;
}

.blockTitleSelected {
    font-weight: bold;
}

.blockImgHolderSelected {
    border-radius: 50px;
    overflow: hidden;
}

.configThird .summary {
    display: flex;
    width: 70%;
    flex-direction: column;
}

.configFirst .holderSofa {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-right: 6px solid rgb(248, 248, 248);
    height: 100%;
    width: 100%;
}

.welcomeImageHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
}

.welcomeImageHolder span {
    font-weight: bold;
    margin-bottom: 5px;
}

.welcomeImageHolder p {
    margin: 10px 0 0 0 !important;
}

.firstTimeSwell {
    width: 800px;
}

.welcomeImages {
    display: flex;
    justify-content: center;
}

.swellPopup p {
    margin: 50px 0;
    font-size: 0.8em;
    padding: 0 20px;
}

.swellPopup .welcomeImages img {
    margin: 0 5px;
}

.configSecond .holderSofa {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-right: 6px solid rgb(248, 248, 248);
    height: 100%;
    width: 100%;
}

.sofaWithDims {
    display: flex;
    justify-content: center;
    min-height: 400px;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.configFirst .sofaUnder {
    height: 50%;
    width: 100%;

    display: flex;
    flex-direction: column;
}

.configSecond .sofaUnder {
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.configFirst .sofa,
.configSecond .sofa {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.configFirst .displaySofa {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    height: 400px;
}

.configSecond .displaySofa {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 20px;
    height: 100%;
}

.configSecond .displaySofa img {
    cursor: pointer;
}

.swal-button {
    padding: 7px 19px;
    border-radius: 2px;
    background-color: rgb(0, 0, 0);
    color: white;
    font-size: 12px;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.swal-button:hover {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(0, 0, 0) !important;
}

.configFirst .sofaImage {
    height: 300px;
}

.configSecond .sofaImage {
    max-height: 200px;
    max-width: 90vw;
    margin: 10px;
    border-radius: 10px;
}

.sofaUnderTitles {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.dropzone.left {
    margin-right: 10px;
}

.configFirst .grid-item {
    padding: 4px;
    display: flex;
    justify-content: center;
    z-index: 4;
}

.itemDimensions {
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.frontalImageHolder {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-padding {
    padding: 0px !important;
    flex-direction: column;
    align-items: center;
}

.configFirst .grid-row {
    position: relative;
}

.configFirst .grid-item-content2 {
    z-index: 4;
    background: white;
    border-radius: 5%;
    cursor: pointer;
}

.configFirst .element {
    position: absolute !important;
    z-index: 4;
}

.configFirst .canBuild {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
}

.configFirst .element__canBuildAbove {
    background-color: red;
}

.configFirst .element__canBuildBelow{
    background-color: blue;

}

.configFirst .element__canBuildLeft {
    background-color: blueviolet;

}

.configFirst .element__canBuildRight {
    background-color: green;

}

.configFirst .empty-cell {
    width: 50px;
    height: 50px;
    /* border: 0.5px solid #eee; */
    z-index: 1 !important;
    cursor: default !important;
}

.configSecond .grid-item {
    padding: 5px;
    display: flex;
    justify-content: center;
}

.configFirst .blockImgHolder,
.configSecond .blockImgHolder {
    height: 100%;
    flex-grow: 2;
}

.configFirst .blockTitle {
    font-weight: bold;
    font-size: 0.7em;
    margin-bottom: 10px;
    text-align: center;
}

.configSecond .blockTitle {
  font-weight: bold;
  font-size: 0.7em;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 10px;
}

.padding10 {
    padding: 10px;
}

.configFirst .grid-item-content {
    z-index: 4;
    box-sizing: border-box;
    display: flex;
    background: white;
    flex-direction: column;
    justify-content: center;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    border-radius: 5%;
    cursor: pointer;
}

.configSecond .grid-item-content {
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    background: white;
    flex-direction: column;
    justify-content: space-between;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
    border-radius: 5%;
    cursor: pointer;
    width: 100px;
}

.configSecond {
    display: flex;
    width: 100%;
}

.configFirst .configuratorDisplay .grid-item-content {
    box-shadow: 0 0 5px 3px rgba(88, 88, 88, 0.068);
    justify-content: space-between;
}

.configSecond .blockImgHolder {
    border-radius: 50px;
    max-width: 50px;
    max-height: 50px;
    overflow: hidden;
}

.rightMenu .summaryItemsHolder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;
}

.rightMenu .summaryItemAmountsHolder {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.rightMenu .summaryItemsHolder span,
.rightMenu .summaryItemAmountsHolder span {
    font-size: 0.8em;
}

.rightMenu .summaryItemsHolder span {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 1;
}

.rightMenu .totalPriceHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rightMenu .confirmSofaButton {
    width: 100%;
    margin-top: 20px;
    background: black;
    color: white;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    cursor: pointer;
}

.uwGegevens {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.uwGegevens .inputs div {
    display: flex;
    flex-direction: column;
}

.uwGegevens .inputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.gegevensKeys span {
    margin: 10px;
    font-size: 0.8em;
}

.gegevensValues {
    padding: 0 10px;
}

.gegevensValues div {
    margin: 2px 0;
}

.confirmSofaLocationHolder {
    width: 100%;
    padding: 20px 10px 0;
}

.confirmSofaLocation {
    display: flex;
    background: white;
    width: 100%;
    flex-direction: column;
    padding: 10px;
}

.confirmSofaLocationHolder .header {
    width: 100%;
    padding: 10px;
    background: rgb(48, 48, 48);
    color: white;
    font-size: 0.8em;
}

.addressLine {
    font-size: 0.8em;
    margin: 2px 0;
}

.bigger {
    font-weight: bold;
}

.placeHolderText {
    font-size: 0.8em;
    text-align: center;
    padding: 0 10px;
}

.blockImageWidth {
    width: 100px;
}

.blockImageLongWidth {
    width: 140px;
}

.blockImageHeight {
    height: 100px;
}

.blockImageLongHeight {
    height: 140px;
}

.blockImageLongerHeight {
    height: 180px;
}

.blockImageLongestHeight {
    height: 285px;
}

.blockImageLongerMidHeight {
    height: 210;
}

.configFirst .sofaUnder,
.holderSofa {
    height: 100%;
    max-width: 100vw;
    overflow: hidden;
}

.configFirst .transformX {
    transform: translateX(45px);
    z-index: 2;
}

.configFirst .transformX- {
    transform: translateX(-45px);
    z-index: 2;
}

.configSecond .longerImage {
    max-height: 210px !important;
}

.poof {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1430px) {
    .configFirst .summaryDisplay {
        flex-direction: column;
    }

    .configFirst .sofaUnder {
        border-top: 6px solid rgb(248, 248, 248);
    }
}

@media screen and (max-width: 1000px) {
    .configFirst,
    .configSecond,
    .configThird {
        flex-direction: column;
    }

    .firstTimeSwell {
        width: 95vw;
    }

    .firstTimeSwell h1 {
        font-size: 1.5em;
    }

    .firstTimeSwell .welcomeImages {
        display: none;
    }

    .configHolder {
        margin: 0 0 20px 0;
    }

    .sofaConfigHeader {
        width: 100vw;
    }

    .sofaConfigHeader img {
        max-height: 100%;
        max-width: 10000px;
    }

    .configFirst .leftMenu,
    .configFirst .summaryDisplay,
    .configSecond .leftMenu,
    .configSecond .colorHolder,
    .configSecond .summaryDisplay,
    .configThird .summary,
    .configThird .rightMenu {
        width: 100%;
    }

    .sofaUnderTitles {
        height: 20%;
    }

    .configFirst .holderSofa,
    .configSecond .holderSofa {
        overflow: hidden;
    }

    .configFirst .displaySofa,
    .configSecond .displaySofa {
        flex-grow: 2;
    }

    .sofaImage {
        max-height: 300px;
        max-width: 90vw;
    }

    .configSecond .longerImage {
        max-height: 500px !important;
    }

    .blockImageWidth {
        width: 70px;
    }

    .blockImageLongWidth {
        width: 110px;
    }

    .blockImageHeight {
        height: 70px;
    }

    .blockImageLongerMidHeight {
        height: 210;
    }

    .blockImageLongHeight {
        height: 100px;
    }

    .blockImageLongerHeight {
        height: 125px;
    }

    .blockImageLongestHeight {
        height: 200px;
    }

    .configFirst .transformX {
        transform: translateX(35px);
    }

    .configFirst .transformX- {
        transform: translateX(-35px);
    }

    .configFirst .sofaUnder,
    .holderSofa {
        min-height: 300px;
        max-width: 100vw;
        overflow: hidden;
    }

}

#nopartner {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
